@import "var.conf";
@import "base.css";
@import "common.css";
@import "page_katayama.css";
@import "page_kawabata.css";
@import "widget.css";


/*---------------------------------
Page CSS 2016.11
CSS 担当者名 Document 2016.11
Last Update 2016.11 担当者名
---------------------------------*/

@media print,
screen and (min-width: 768px) {
    /*
  404
  -------------------------------------*/
    #Page404 #Main {
        #ContBox01 {
            #ContSubBox01 {}
        }
    }
    /*
  ホーム
  -------------------------------------*/
    #Page.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  セミナー
  -------------------------------------*/
    #PageSeminar.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  投資コンテンツ
  -------------------------------------*/
    #PageService.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  投資で成功を望む方へ
  -------------------------------------*/
    #PageSuccess.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  会社概要
  -------------------------------------*/
    #PageCompany.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  お客様の声
  -------------------------------------*/
    #PageVoice.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  著書紹介
  -------------------------------------*/
    #PageBooks.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  Facebook
  -------------------------------------*/
    #PageFacebook.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  アフィリエイター様へ
  -------------------------------------*/
    #PageAffiliate.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  お問い合わせ
  -------------------------------------*/
    #PageContact.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  お問い合わせ
  -------------------------------------*/
    #PageContact.pageConfirm #Main {
        #ContBox01 {}
    }
    /*
  お問い合わせ
  -------------------------------------*/
    #PageContact.pageThanks #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  プライバシーポリシー
  -------------------------------------*/
    #PagePrivacy.pageIndex #Main {
        #ContBox01 {
            .privacy {
                margin-top: 30px;
                h4 {
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 25px;
                    margin-top: 15px;
                    margin-bottom: 10px;
                    padding-bottom: 5px;
                    border-bottom-color: #cccccc;
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                p {
                    line-height: 25px;
                    font-size: 15px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                ul {
                    font-size: 15px;
                    line-height: 25px;
                    margin: 10px 0 10px 25px;
                    padding: 0;
                    list-style-type: disc;
                    list-style-position: outside;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    /*
  特定商取引法上の表記
  -------------------------------------*/
    #PageTokutei.pageIndex #Main {
        #ContBox01 {
            .tokutei {
                width: 100%;
                color: #000000;
                dl {
                    display: table;
                    width: 100%;
                    border-right: 1px solid #cccccc;
                    border-bottom: 1px solid #cccccc;
                    border-left: 1px solid #cccccc;
                }
                dl:first-child {
                    width: 100%;
                    border-top: 1px solid #cccccc;
                }
                dt {
                    display: table-cell;
                    width: 180px;
                    border-right: 1px solid #cccccc;
                    font-size: 15px;
                    vertical-align: middle;
                    line-height: 25px;
                    background-color: #ededed;
                    text-align: center;
                    padding: 8px 15px;
                }
                dd {
                    display: table-cell;
                    font-size: 15px;
                    vertical-align: middle;
                    line-height: 25px;
                    padding: 8px 15px;
                    a {
                        color: #000000;
                    }
                    a:link,
                    a:visited {
                        color: #333333;
                        text-decoration: underline;
                    }
                    a:active,
                    a:hover {
                        color: #333333;
                        text-decoration: none;
                        opacity: 0.5;
                    }
                    p {
                        margin-top: 10px;
                    }
                    p:first-child {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    /*
  サイトマップ
  -------------------------------------*/
    #PageSitemap.pageIndex #Main {
        #ContBox01 {
            .siteMap {
                width: 100%;
                .floatLeft {
                    width: 48%;
                    float: left;
                    li {
                        margin: 5px 0;
                        padding: 5px 0 10px 25px;
                        background: url(../img/iconArrow.png) 5px 6px no-repeat;
                        border-bottom: 1px dashed #cccccc;
                        font-size: 15px;
                        a,
                        a:link {
                            color: #000000;
                        }
                        a:visited {}
                        a:hover {
                            opacity: 0.5;
                        }
                        a:active {}
                    }
                }
                .floatRight {
                    width: 48%;
                    float: right;
                    li {
                        margin: 5px 0;
                        padding: 5px 0 10px 25px;
                        background: url(../img/iconArrow.png) 5px 6px no-repeat;
                        border-bottom: 1px dashed #cccccc;
                        font-size: 15px;
                        a,
                        a:link {
                            color: #000000;
                        }
                        a:visited {}
                        a:hover {
                            opacity: 0.5;
                        }
                        a:active {}
                    }
                }
            }
        }
    }
    /*
  問い合わせサンプル
  -------------------------------------*/
    #PageContact_sample.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  問い合わせサンプル確認
  -------------------------------------*/
    #PageContact_sample.pageConfirm #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  問い合わせサンプル完了
  -------------------------------------*/
    #PageContact_sample.pageThanks #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログMTトップ
  -------------------------------------*/
    #PageBlog_sample_mt.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログMTカテゴリー
  -------------------------------------*/
    #PageBlog_sample_mt.pageCategory #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログMT記事詳細
  -------------------------------------*/
    #PageBlog_sample_mt.pageEntry #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログWPトップ
  -------------------------------------*/
    #PageBlog_sample_wp.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログWPカテゴリー
  -------------------------------------*/
    #PageBlog_sample_wp.pageCategory #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログWP記事詳細
  -------------------------------------*/
    #PageBlog_sample_wp.pageEntry #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
}

@media print,
screen and (max-width: 767px) {
    /*
  404
  -------------------------------------*/
    #Page404 #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ホーム
  -------------------------------------*/
    #Page.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  セミナー
  -------------------------------------*/
    #PageSeminar.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  投資コンテンツ
  -------------------------------------*/
    #PageService.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  投資で成功を望む方へ
  -------------------------------------*/
    #PageSuccess.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  会社概要
  -------------------------------------*/
    #PageCompany.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  お客様の声
  -------------------------------------*/
    #PageVoice.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  著書紹介
  -------------------------------------*/
    #PageBooks.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  Facebook
  -------------------------------------*/
    #PageFacebook.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  アフィリエイター様へ
  -------------------------------------*/
    #PageAffiliate.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  お問い合わせ
  -------------------------------------*/
    #PageContact.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  お問い合わせ
  -------------------------------------*/
    #PageContact.pageConfirm #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  お問い合わせ
  -------------------------------------*/
    #PageContact.pageThanks #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  プライバシーポリシー
  -------------------------------------*/
    #PagePrivacy.pageIndex #Main {
        #ContBox01 {
            .privacy {
                margin-top: 30px;
                &.second {
                    margin-bottom: 40px;
                }
                h4 {
                    font-weight: bold;
                    font-size: 15px;
                    margin-top: 15px;
                    margin-bottom: 10px;
                    padding-bottom: 5px;
                    border-bottom-color: #cccccc;
                    border-bottom-style: solid;
                    border-bottom-width: 1px;
                }
                p {
                    line-height: 25px;
                    font-size: 15px;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                ul {
                    font-size: 15px;
                    margin: 10px 0 10px 25px;
                    padding: 0;
                    list-style-type: disc;
                    list-style-position: outside;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    /*
  特定商取引法上の表記
  -------------------------------------*/
    #PageTokutei.pageIndex #Main {
        #ContBox01 {
            .tokutei {
                width: 100%;
                color: #000000;
                dl {
                    display: table;
                    width: 100%;
                    border-right: 1px solid #cccccc;
                    border-bottom: 1px solid #cccccc;
                    border-left: 1px solid #cccccc;
                }
                dl:first-child {
                    width: 100%;
                    border-top: 1px solid #cccccc;
                }
                dt {
                    display: table-cell;
                    width: 90px;
                    border-right: 1px solid #cccccc;
                    font-size: 1px;
                    vertical-align: middle;
                    line-height: 15px;
                    background-color: #ededed;
                    text-align: center;
                    padding: 8px 4px;
                }
                dd {
                    display: table-cell;
                    font-size: 1px;
                    vertical-align: middle;
                    line-height: 15px;
                    padding: 8px 4px;
                    a {
                        color: #000000;
                    }
                    a:link,
                    a:visited {
                        color: #333333;
                        text-decoration: underline;
                    }
                    a:active,
                    a:hover {
                        color: #333333;
                        text-decoration: none;
                        opacity: 0.5;
                    }
                    p {
                        margin-top: 10px;
                    }
                    p:first-child {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    /*
  サイトマップ
  -------------------------------------*/
    #PageSitemap.pageIndex #Main {
        #ContBox01 {
            .siteMap {
                width: 100%;
                .floatLeft,
                .floatRight {
                    li {
                        margin: 5px 0;
                        padding: 10px 0 10px 30px;
                        background: url(../img/iconArrow.png) 10px 10px no-repeat;
                        border-bottom: 1px dashed #cccccc;
                        font-size: 15px;
                        a,
                        a:link {
                            color: #000000;
                        }
                        a:visited {}
                        a:hover {
                            opacity: 0.5;
                        }
                        a:active {}
                    }
                }
            }
        }
    }
    /*
  問い合わせサンプル
  -------------------------------------*/
    #PageContact_sample.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  問い合わせサンプル確認
  -------------------------------------*/
    #PageContact_sample.pageConfirm #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  問い合わせサンプル完了
  -------------------------------------*/
    #PageContact_sample.pageThanks #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログMTトップ
  -------------------------------------*/
    #PageBlog_sample_mt.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログMTカテゴリー
  -------------------------------------*/
    #PageBlog_sample_mt.pageCategory #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログMT記事詳細
  -------------------------------------*/
    #PageBlog_sample_mt.pageEntry #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログWPトップ
  -------------------------------------*/
    #PageBlog_sample_wp.pageIndex #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログWPカテゴリー
  -------------------------------------*/
    #PageBlog_sample_wp.pageCategory #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
    /*
  ブログWP記事詳細
  -------------------------------------*/
    #PageBlog_sample_wp.pageEntry #Main {
        #ContBox01 {
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
}
