@charset "UTF-8";
@import url(base.css);
@import url(common.css);
@import url(page_katayama.css);
@import url(page_kawabata.css);
@import url(widget.css);
/*---------------------------------
Page CSS 2016.11
CSS 担当者名 Document 2016.11
Last Update 2016.11 担当者名
---------------------------------*/
@media print, screen and (min-width: 768px) {
  /*
  404
  -------------------------------------*/
  /*
  ホーム
  -------------------------------------*/
  /*
  セミナー
  -------------------------------------*/
  /*
  投資コンテンツ
  -------------------------------------*/
  /*
  投資で成功を望む方へ
  -------------------------------------*/
  /*
  会社概要
  -------------------------------------*/
  /*
  お客様の声
  -------------------------------------*/
  /*
  著書紹介
  -------------------------------------*/
  /*
  Facebook
  -------------------------------------*/
  /*
  アフィリエイター様へ
  -------------------------------------*/
  /*
  お問い合わせ
  -------------------------------------*/
  /*
  お問い合わせ
  -------------------------------------*/
  /*
  お問い合わせ
  -------------------------------------*/
  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePrivacy.pageIndex #Main #ContBox01 .privacy {
    margin-top: 30px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .privacy h4 {
      font-weight: bold;
      font-size: 15px;
      line-height: 25px;
      margin-top: 15px;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom-color: #cccccc;
      border-bottom-style: solid;
      border-bottom-width: 1px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .privacy p {
      line-height: 25px;
      font-size: 15px;
      margin-top: 0px;
      margin-bottom: 0px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .privacy ul {
      font-size: 15px;
      line-height: 25px;
      margin: 10px 0 10px 25px;
      padding: 0;
      list-style-type: disc;
      list-style-position: outside;
      margin-top: 10px;
      margin-bottom: 10px; }
  /*
  特定商取引法上の表記
  -------------------------------------*/
  #PageTokutei.pageIndex #Main #ContBox01 .tokutei {
    width: 100%;
    color: #000000; }
    #PageTokutei.pageIndex #Main #ContBox01 .tokutei dl {
      display: table;
      width: 100%;
      border-right: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      border-left: 1px solid #cccccc; }
    #PageTokutei.pageIndex #Main #ContBox01 .tokutei dl:first-child {
      width: 100%;
      border-top: 1px solid #cccccc; }
    #PageTokutei.pageIndex #Main #ContBox01 .tokutei dt {
      display: table-cell;
      width: 180px;
      border-right: 1px solid #cccccc;
      font-size: 15px;
      vertical-align: middle;
      line-height: 25px;
      background-color: #ededed;
      text-align: center;
      padding: 8px 15px; }
    #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd {
      display: table-cell;
      font-size: 15px;
      vertical-align: middle;
      line-height: 25px;
      padding: 8px 15px; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a {
        color: #000000; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a:link,
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a:visited {
        color: #333333;
        text-decoration: underline; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a:active,
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a:hover {
        color: #333333;
        text-decoration: none;
        opacity: 0.5; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd p {
        margin-top: 10px; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd p:first-child {
        margin-top: 0px; }
  /*
  サイトマップ
  -------------------------------------*/
  #PageSitemap.pageIndex #Main #ContBox01 .siteMap {
    width: 100%; }
    #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatLeft {
      width: 48%;
      float: left; }
      #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatLeft li {
        margin: 5px 0;
        padding: 5px 0 10px 25px;
        background: url(../img/iconArrow.png) 5px 6px no-repeat;
        border-bottom: 1px dashed #cccccc;
        font-size: 15px; }
        #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatLeft li a,
        #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatLeft li a:link {
          color: #000000; }
        #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatLeft li a:hover {
          opacity: 0.5; }
    #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatRight {
      width: 48%;
      float: right; }
      #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatRight li {
        margin: 5px 0;
        padding: 5px 0 10px 25px;
        background: url(../img/iconArrow.png) 5px 6px no-repeat;
        border-bottom: 1px dashed #cccccc;
        font-size: 15px; }
        #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatRight li a,
        #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatRight li a:link {
          color: #000000; }
        #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatRight li a:hover {
          opacity: 0.5; }
  /*
  問い合わせサンプル
  -------------------------------------*/
  /*
  問い合わせサンプル確認
  -------------------------------------*/
  /*
  問い合わせサンプル完了
  -------------------------------------*/
  /*
  ブログMTトップ
  -------------------------------------*/
  /*
  ブログMTカテゴリー
  -------------------------------------*/
  /*
  ブログMT記事詳細
  -------------------------------------*/
  /*
  ブログWPトップ
  -------------------------------------*/
  /*
  ブログWPカテゴリー
  -------------------------------------*/
  /*
  ブログWP記事詳細
  -------------------------------------*/ }

@media print, screen and (max-width: 767px) {
  /*
  404
  -------------------------------------*/
  /*
  ホーム
  -------------------------------------*/
  /*
  セミナー
  -------------------------------------*/
  /*
  投資コンテンツ
  -------------------------------------*/
  /*
  投資で成功を望む方へ
  -------------------------------------*/
  /*
  会社概要
  -------------------------------------*/
  /*
  お客様の声
  -------------------------------------*/
  /*
  著書紹介
  -------------------------------------*/
  /*
  Facebook
  -------------------------------------*/
  /*
  アフィリエイター様へ
  -------------------------------------*/
  /*
  お問い合わせ
  -------------------------------------*/
  /*
  お問い合わせ
  -------------------------------------*/
  /*
  お問い合わせ
  -------------------------------------*/
  /*
  プライバシーポリシー
  -------------------------------------*/
  #PagePrivacy.pageIndex #Main #ContBox01 .privacy {
    margin-top: 30px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .privacy.second {
      margin-bottom: 40px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .privacy h4 {
      font-weight: bold;
      font-size: 15px;
      margin-top: 15px;
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom-color: #cccccc;
      border-bottom-style: solid;
      border-bottom-width: 1px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .privacy p {
      line-height: 25px;
      font-size: 15px;
      margin-top: 0px;
      margin-bottom: 0px; }
    #PagePrivacy.pageIndex #Main #ContBox01 .privacy ul {
      font-size: 15px;
      margin: 10px 0 10px 25px;
      padding: 0;
      list-style-type: disc;
      list-style-position: outside;
      margin-top: 10px;
      margin-bottom: 10px; }
  /*
  特定商取引法上の表記
  -------------------------------------*/
  #PageTokutei.pageIndex #Main #ContBox01 .tokutei {
    width: 100%;
    color: #000000; }
    #PageTokutei.pageIndex #Main #ContBox01 .tokutei dl {
      display: table;
      width: 100%;
      border-right: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      border-left: 1px solid #cccccc; }
    #PageTokutei.pageIndex #Main #ContBox01 .tokutei dl:first-child {
      width: 100%;
      border-top: 1px solid #cccccc; }
    #PageTokutei.pageIndex #Main #ContBox01 .tokutei dt {
      display: table-cell;
      width: 90px;
      border-right: 1px solid #cccccc;
      font-size: 1px;
      vertical-align: middle;
      line-height: 15px;
      background-color: #ededed;
      text-align: center;
      padding: 8px 4px; }
    #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd {
      display: table-cell;
      font-size: 1px;
      vertical-align: middle;
      line-height: 15px;
      padding: 8px 4px; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a {
        color: #000000; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a:link,
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a:visited {
        color: #333333;
        text-decoration: underline; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a:active,
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd a:hover {
        color: #333333;
        text-decoration: none;
        opacity: 0.5; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd p {
        margin-top: 10px; }
      #PageTokutei.pageIndex #Main #ContBox01 .tokutei dd p:first-child {
        margin-top: 0px; }
  /*
  サイトマップ
  -------------------------------------*/
  #PageSitemap.pageIndex #Main #ContBox01 .siteMap {
    width: 100%; }
    #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatLeft li,
    #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatRight li {
      margin: 5px 0;
      padding: 10px 0 10px 30px;
      background: url(../img/iconArrow.png) 10px 10px no-repeat;
      border-bottom: 1px dashed #cccccc;
      font-size: 15px; }
      #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatLeft li a,
      #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatLeft li a:link,
      #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatRight li a,
      #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatRight li a:link {
        color: #000000; }
      #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatLeft li a:hover,
      #PageSitemap.pageIndex #Main #ContBox01 .siteMap .floatRight li a:hover {
        opacity: 0.5; }
  /*
  問い合わせサンプル
  -------------------------------------*/
  /*
  問い合わせサンプル確認
  -------------------------------------*/
  /*
  問い合わせサンプル完了
  -------------------------------------*/
  /*
  ブログMTトップ
  -------------------------------------*/
  /*
  ブログMTカテゴリー
  -------------------------------------*/
  /*
  ブログMT記事詳細
  -------------------------------------*/
  /*
  ブログWPトップ
  -------------------------------------*/
  /*
  ブログWPカテゴリー
  -------------------------------------*/
  /*
  ブログWP記事詳細
  -------------------------------------*/ }
